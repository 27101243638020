import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilValue } from "recoil";

import { useNetwork, useNotification } from "hooks";
import { API_URL } from "constant";
import { getDateWithTime, getJsonToParams } from "utils";
import { operationInputState } from "states";
import { formatNumberWithCommas } from "utils/fomat-number";
import { blockchainImageConfig } from "./constant";
import { Image, Loader } from "@storybook";
import { Search } from "views";

import "./operation.scss";

export const Operation = () => {
  const globalSearch = useRecoilValue(operationInputState);
  const [operations, setOperations] = useState<any[]>([]);
  const refAssetInterval = useRef<any>(null);
  const [loadingAsset,setLoadingAsset] = useState<boolean>(false);

  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
  });

  const { successNotification } = useNotification();

  const { get: getOperations } = useNetwork();
  const fetchApi = useCallback(() => {
    getOperations(`${API_URL.OPERATIONS}`)
      .then((exchangeOperations) => {
        console.log(exchangeOperations);
        const { apiData } = exchangeOperations;
        setOperations(apiData?.data);
      })
      .catch((err) => {
        console.log("ERRRRRRR", err);
      });
  }, []);

  const NewObjectWithNewIdAdded = useCallback(
    (previousArray: any, currentArray: any) => {
      const previousIds = previousArray.map((obj: any) => obj.id);
      const newSBA7Set = new Set(previousIds);
      if (!newSBA7Set.has(currentArray[0]?.id)) {
        return currentArray[0];
      } else {
        return "";
      }
    },
    []
  );
   useEffect(() => {
     refAssetInterval.current = setInterval(() => {
       getOperations(`${API_URL.OPERATIONS}`).then((res: any) => {
         if (res?.message === "ok") {
           const newSBA7Asset = NewObjectWithNewIdAdded(
             operations,
             res?.data?.sba7
           );
           if (newSBA7Asset) {
             setOperations((prev: any) => {
               return [newSBA7Asset, ...prev];
             });
           }
         }
       });
     }, 5000);
     return () => clearInterval(refAssetInterval.current);
   }, [operations]);

   const handleFetchMore = useCallback(() => {
    setLoadingAsset(true);
     const queryParams = { ...filters, offset: filters.offset + 1 };
     getOperations(`${API_URL.OPERATIONS}` + getJsonToParams(queryParams)).then(
       (res: any) => {
         setLoadingAsset(false);
         if (res) {
           const { apiData } = res;
           setOperations((prev: any) => {
             return [...prev, ...apiData?.data];
           });
         }
       }
     );
     setFilters(() => ({ ...queryParams }));
     
   }, [filters]);
  const copy = useCallback(
    (id: string) => {
      navigator.clipboard.writeText(id);
      successNotification("Copied");
    },
    [successNotification]
  );

  useEffect(() => {
    fetchApi();
  }, []);

  const operationData = operations?.filter(
    (item: any) =>
      item?.assetName?.toLowerCase()?.includes(globalSearch?.toLowerCase()) ||
      item?.assetSymbol?.toLowerCase()?.includes(globalSearch?.toLowerCase()) ||
      item?.transactionId === globalSearch ||
      item?.buyerWalletId === globalSearch ||
      item?.sellerWalletId === globalSearch
  );

  const handelView = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const signers = useCallback((items: any) => {
    return (
      <>
        {" "}
        {items?.length > 0 && (
          <>
            <div className="wallet-header">
              Required signers : <span>2</span>
            </div>
            <div className="wallet-header">
              Total signers : <span>3</span>
            </div>
          </>
        )}
      </>
    );
  }, []);

  const polygonDetail = useCallback((items: any) => {
    return (
      <>
        <div className={"wallet--cell-border"}>
          {items?.map((item: any) => (
            <div className="wallet-polygonData">
              <div className="wallet-header">SIgner ID</div>
              <span className="text-ellipse">
                {item?.transaction?.signerId}
              </span>
              <i
                onClick={() => copy(item?.transaction?.signerId)}
                className="ri-file-copy-fill copyIcon"
              ></i>
              <div className="wallet-header">Blockchain hash</div>
              <div className="blockchain-hash">
              <span
                className="text-ellipse"
                onClick={() => handelView(item?.transaction?.url)}
              >
                {item?.transaction?.hash}
              </span>
              <i
                onClick={() => copy(item?.transaction?.url)}
                className="ri-file-copy-fill copyIcon"
              ></i>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }, []);

  const renderOperation = useCallback((item: any) => {
    const {
      type,
      assetName,
      assetSymbol,
      date,
      price,
      quantity,
      total,
      transactionId,
      polygonData,
      buyerUserId,
      sellerUserId,
      sellerWalletId,
      buyerWalletId,
      status,
      userName,
      userWalletId,
      tax,
      blockchain
    } = item;
    const { approval, transaction } = polygonData ?? {};
    switch (type) {
      case "BUY":
        return (
          <div className="operation-row-wrapper">
            <div className="operation-row">
              <div className="operation-cell">
                <span className="operation-symbol">{assetSymbol?.toUpperCase()}</span>{" "}
                <span className="cell-header">({assetName})</span>
                <div  className="operation-blockchain">
                  <span className="cell-header">Blockchain : </span>
                <span className="operation-blockchain-title"><img className="operation-blockchain-logo" src={ blockchainImageConfig[blockchain || ""]} alt="blockchain logo"/>{blockchain}</span>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Quantity : </span>
                    {formatNumberWithCommas(quantity, 8)}
                  </div>
                  <div>
                    <span className="cell-header">Price : </span>$
                    {formatNumberWithCommas(Number(price), 8)}
                  </div>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Fee : </span>${formatNumberWithCommas(Number(tax.taxAmount), 8)}
                  </div>
                  {/* <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total))}
                  </div> */}
                  <div>
                    <span className="cell-header">Order type : </span>{" "}
                    <span className="buy-cell"></span> Buy
                  </div>
                </div>
                <div className="operation--cell">
                <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total), 8)}
                  </div>
                  <div>
                    <span className="cell-header">Status : </span>
                    <span
                      className={status === "PENDING" ? "yellowDot" : "greyDot"}
                    ></span>
                    {status}
                  </div>
                </div>
              </div>
              <div className="operation-cell">
                {status === "PENDING" && (
                  <div className="operatio-cell-copy">
                    ATS subscriber {userName} has notified us (FirstBank) that a
                    Buy order was submitted by {userName} to ARQ Advisors ATS,
                    the terms of which are attached hereto. {userName} has
                    notified us (FirstBank) that should the Buy order be
                    executed, we (FirstBank) should settle the transaction in
                    accordance with the terms of this order with the Seller’s
                    Securities Custodian when so prompted by a notification of
                    ARQ Advisors ATS.
                  </div>
                )}
                <div className="operatio-cell-copy">
                  -&gt; Buy order placed: Sent to API
                </div>
                <div className="operatio-cell-copy">
                  -&gt; Transaction ID: {transactionId}
                </div>
              </div>
              <div className="operation-cell">{getDateWithTime(date)}</div>
            </div>
            <div className="wallet-row">
              <div className="wallet--cell">
                {buyerUserId && (
                  <div className={userWalletId ? "wallet--cell-border" : ""}>
                    <div
                      className={
                        polygonData?.buyerTransactions?.length
                          ? "wallet-polygonData wallet-left"
                          : ""
                      }
                    >
                      <div className="wallet-header">Buyer ID</div>
                      {buyerUserId}
                      <i
                        onClick={() => copy(buyerUserId)}
                        className="ri-file-copy-fill copyIcon"
                      ></i>
                    </div>
                    {signers(polygonData?.buyerTransactions)}
                  </div>
                )}

                {polygonData?.buyerTransactions?.length ? (
                  polygonDetail(polygonData?.buyerTransactions)
                ) : (
                  <>
                    {userWalletId && (
                      <div
                        className={userWalletId ? "wallet--cell-border" : ""}
                      >
                        <div className="wallet-header">Buyer wallet add.</div>
                        {userWalletId}
                        <i
                          onClick={() => copy(userWalletId)}
                          className="ri-file-copy-fill copyIcon"
                        ></i>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {sellerUserId && (
              <div className="wallet-row">
                <div className="wallet--cell">
                  {sellerUserId && (
                    <div className="wallet--cell-border">
                      <span className="wallet-header">Seller ID : </span>
                      {sellerUserId}
                      <i
                        onClick={() => copy(sellerUserId)}
                        className="ri-file-copy-fill copyIcon"
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      case "SELL":
        return (
          <div className="operation-row-wrapper">
            <div className="operation-row">
              <div className="operation-cell">
                <span className="operation-symbol">{assetSymbol?.toUpperCase()}</span>{" "}
                <span className="cell-header">({assetName})</span>
                <div  className="operation-blockchain">
                  <span className="cell-header">Blockchain : </span>
                <span className="operation-blockchain-title"><img className="operation-blockchain-logo" src={ blockchainImageConfig[blockchain || ""]} alt="blockchain logo"/>{blockchain}</span>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Quantity : </span> {formatNumberWithCommas(quantity, 8)}
                  </div>
                  <div>
                    <span className="cell-header">Price : </span>$
                    {formatNumberWithCommas(Number(price), 8)}
                  </div>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Fee : </span>${formatNumberWithCommas(Number(tax.taxAmount), 8)}
                  </div>
                  {/* <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total))}
                  </div> */}
                  <div>
                    <span className="cell-header">Order type : </span>
                    <span className="sell-cell"></span> Sell
                  </div>
                </div>
                <div className="operation--cell">
                <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total), 8)}
                  </div>
                  <div>
                    <span className="cell-header">Status : </span>
                    <span
                      className={status === "PENDING" ? "yellowDot" : "greyDot"}
                    ></span>
                    {status}
                  </div>
                </div>
              </div>
              <div className="operation-cell">
                {status === "PENDING" && (
                  <div className="operatio-cell-copy">
                    ATS subscriber {userName} has notified us (Liquidity
                    Transfer Agent) that a Sell order was submitted by{" "}
                    {userName} to ARQ Advisors ATS, the terms of which are
                    attached hereto. {userName} has notified us (Liquidity
                    Transfer Agent) that should the Sell order be executed, we
                    (Liquidity Transfer Agent) should settle the transaction in
                    accordance with the terms of this order with the Buyer’s
                    Cash Custodian when so prompted by a notification of ARQ
                    Advisors ATS.
                  </div>
                )}
                <div className="operatio-cell-copy">
                  -&gt; Sell order placed: Sent to API{" "}
                </div>
                <div className="operatio-cell-copy">
                  {" "}
                  -&gt; Transaction ID : {transactionId}
                </div>
              </div>
              <div className="operation-cell">{getDateWithTime(date)}</div>
            </div>
            <div className="wallet-row">
              <div className="wallet--cell">
                {sellerUserId && (
                  <div className={userWalletId ? "wallet--cell-border" : ""}>
                    <div
                      className={
                        polygonData?.sellerTransactions?.length
                          ? "wallet-polygonData wallet-left"
                          : ""
                      }
                    >
                      <div className="wallet-header">Seller ID</div>
                      {sellerUserId}
                      <i
                        onClick={() => copy(sellerUserId)}
                        className="ri-file-copy-fill copyIcon"
                      ></i>
                    </div>
                    {signers(polygonData?.sellerTransactions)}
                  </div>
                )}
                {polygonData?.sellerTransactions?.length ? (
                  polygonDetail(polygonData?.sellerTransactions)
                ) : (
                  <>
                    {userWalletId && (
                      <div
                        className={userWalletId ? "wallet--cell-border" : ""}
                      >
                        <div className="wallet-header">Seller wallet add.</div>
                        {userWalletId}
                        <i
                          onClick={() => copy(userWalletId)}
                          className="ri-file-copy-fill copyIcon"
                        ></i>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="operation-row-wrapper">
            <div className="operation-row">
              <div className="operation-cell">
                <span className="operation-symbol">{assetSymbol?.toUpperCase()}</span>{" "}
                <span className="cell-header">({assetName})</span>
                <div  className="operation-blockchain">
                  <span className="cell-header">Blockchain : </span>
                <span className="operation-blockchain-title"><img className="operation-blockchain-logo" src={ blockchainImageConfig[blockchain || ""]} alt="blockchain logo"/>{blockchain}</span>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Quantity : </span> {formatNumberWithCommas(quantity, 8)}
                  </div>
                  <div>
                    <span className="cell-header">Price : </span>$
                    {formatNumberWithCommas(Number(price), 8)}
                  </div>
                </div>
                <div className="operation--cell">
                  <div>
                    <span className="cell-header">Fee : </span>${formatNumberWithCommas(Number(tax.taxAmount), 8)}
                  </div>
                  {/* <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total))}
                  </div> */}
                  <div>
                    <span className="cell-header">Status : </span>
                    <span className="excuted-cell"></span> Executed
                  </div>
                </div>
                <div>
                    <span className="cell-header">Total : </span>$
                    {formatNumberWithCommas(Number(total), 8)}
                  </div>
              </div>
              <div className="operation-cell">
                <div className="operatio-cell-copy">
                  -&gt; Order matched and executed
                </div>

                {approval?.hash && (
                  <div className="operatio-cell-copy hover-cell">
                    <div className="copy-cell-row ">
                      -&gt; Blockchain approval ID :{" "}
                      <span className="text-ellipse "> {approval?.hash} </span>
                    </div>
                    <div className="copy-cell-row">
                      {" "}
                      <button onClick={() => copy(approval?.hash)}>
                        <i className="ri-file-copy-line"></i> Copy
                      </button>
                      <button onClick={() => handelView(approval?.url)}>
                        <i className="ri-share-box-line"></i> View
                      </button>
                    </div>
                  </div>
                )}
                {transactionId && (
                  <div className="operatio-cell-copy hover-cell">
                    <div className="copy-cell-row">
                      -&gt; Transaction ID :{" "}
                      <span className="text-ellipse databaseId">
                        {" "}
                        {transactionId}{" "}
                      </span>
                    </div>
                    <div className="copy-cell-row">
                      <button onClick={() => copy(transactionId)}>
                        <i className="ri-file-copy-line"></i> Copy
                      </button>
                    </div>
                  </div>
                )}
                {transaction?.hash && (
                  <div className="operatio-cell-copy hover-cell">
                    <div className="copy-cell-row">
                      -&gt; Blockchain transaction ID :{" "}
                      <span className="text-ellipse">
                        {" "}
                        {transaction?.hash}{" "}
                      </span>
                    </div>
                    <div className="copy-cell-row">
                      <button onClick={() => copy(transaction?.hash)}>
                        <i className="ri-file-copy-line"></i> Copy
                      </button>
                      <button onClick={() => handelView(transaction?.url)}>
                        <i className="ri-share-box-line"></i> View
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="operation-cell">{getDateWithTime(date)}</div>
            </div>
            <div className="wallet-row">
              <div className="wallet--cell">
                {buyerUserId && (
                  <div className={buyerWalletId ? "wallet--cell-border" : ""}>
                    <div
                      className={
                        polygonData?.buyerTransactions?.length
                          ? "wallet-polygonData wallet-left"
                          : ""
                      }
                    >
                      <div className="wallet-header">Buyer ID</div>
                      {buyerUserId}
                      <i
                        onClick={() => copy(buyerUserId)}
                        className="ri-file-copy-fill copyIcon"
                      ></i>
                    </div>
                    {signers(polygonData?.buyerTransactions)}
                  </div>
                )}

                {polygonData?.buyerTransactions?.length ? (
                  polygonDetail(polygonData?.buyerTransactions)
                ) : (
                  <>
                    {buyerWalletId && (
                      <div
                        className={buyerWalletId ? "wallet--cell-border" : ""}
                      >
                        <div className="wallet-header">Buyer wallet add.</div>
                        {buyerWalletId}
                        <i
                          onClick={() => copy(buyerWalletId)}
                          className="ri-file-copy-fill copyIcon"
                        ></i>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="wallet-row">
              <div className="wallet--cell">
                {sellerUserId && (
                  <div className={sellerWalletId ? "wallet--cell-border" : ""}>
                    <div
                      className={
                        polygonData?.sellerTransactions?.length
                          ? "wallet-polygonData wallet-left"
                          : ""
                      }
                    >
                      <div className="wallet-header">Seller ID</div>
                      {sellerUserId}
                      <i
                        onClick={() => copy(sellerUserId)}
                        className="ri-file-copy-fill copyIcon"
                      ></i>
                    </div>

                    {signers(polygonData?.sellerTransactions)}
                  </div>
                )}

                {polygonData?.sellerTransactions?.length ? (
                  polygonDetail(polygonData?.sellerTransactions)
                ) : (
                  <>
                    {sellerWalletId && (
                      <div
                        className={sellerWalletId ? "wallet--cell-border" : ""}
                      >
                        <div className="wallet-header">Seller wallet add.</div>
                        {sellerWalletId}
                        <i
                          onClick={() => copy(sellerWalletId)}
                          className="ri-file-copy-fill copyIcon"
                        ></i>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
    }
  }, []);

  return (
    <div className="asset-operation">
      <Search placeholder="Search transaction ID, blockchain approval ID" />
      <div className="asset--operation">
        <h3>Latest operations</h3>
        {operationData?.length ? (
          <>
            <div className="operation-row operation-header operation-row-wrapper">
              <div className="operation-cell">Instructions</div>
              <div className="operation-cell">Operation</div>
              <div className="operation-cell">Date & Time</div>
            </div>
            <div className="operation-body">
              <InfiniteScroll
                dataLength={operations.length || 0}
                next={handleFetchMore}
                hasMore={
                  operations.length >=
                  filters.offset * filters.limit + filters.limit
                }
                loader={""}
                scrollableTarget="assets-scrollableDiv"
              >
                {operationData?.map((item: any) => (
                  <>{renderOperation(item)}</>
                ))}
              </InfiniteScroll>
              {loadingAsset && <Loader />}
            </div>
          </>
        ) : (
          <div className="empty-data">
            <Image fileName={"images/no_data_dark.svg"} />

            <div>
              No Result
              {globalSearch && <> for "{globalSearch}"</>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
